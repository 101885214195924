import {
    Box, Button, Flex, HStack,
    Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter,
    ModalHeader, ModalOverlay, Spacer, Text, Textarea, useToast, VStack
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-router-dom";
import HomeSidebar from "../../components/SideBar";
import { MainContainer } from "../../components/MainContainer";
import { isAuthKeyValid } from "../../services/auth.service";

export default function AccountSettingsPage() {

    const navigate = useNavigate();

    useEffect(() => {
        async function verifyAuth() {
            if (!(await isAuthKeyValid())) {

                navigate("/auth/login", {replace: true});

            }
        }

        verifyAuth();
    }, [])

    return <Flex direction="row">

        <HomeSidebar />
        <MainContainer>
            <Flex

                color='whiteAlpha.900' direction="column"
                alignItems="center"
            >
                <Box >
                    <Text fontSize={{ lg: 50 }} fontWeight={{ lg: 100 }}>Profile</Text>
                </Box>


                <VStack width={'100%'} spacing={{ base: 6, md: 6 }} marginTop={{ lg: 30 }}>
                    <Box
                        width={'300px'}

                    >
                        <Text
                            marginBottom={'10px'}
                        >Username</Text>
                        <Input

                            type='text'
                            // onKeyDown = {()=>alert('hello')}
                            borderColor={'whiteAlpha.900'}

                            borderWidth={1} borderStyle={'solid'} />

                    </Box>

                    <Box
                        width={'300px'}

                    >
                        <Text
                            marginBottom={'10px'}
                        >Email</Text>
                        <Input

                            type='text'
                            // onKeyDown = {()=>alert('hello')}
                            borderColor={'whiteAlpha.900'}

                            borderWidth={1} borderStyle={'solid'} />

                    </Box>

                    <Box
                        width={'300px'}

                    >
                        <Text
                            marginBottom={'10px'}
                        >Old Password</Text>
                        <Input

                            type='password'
                            // onKeyDown = {()=>alert('hello')}
                            borderColor={'whiteAlpha.900'}

                            borderWidth={1} borderStyle={'solid'} />

                    </Box>

                    <Box
                        width={'300px'}

                    >
                        <Text
                            marginBottom={'10px'}
                        >New Password</Text>
                        <Input

                            type='password'
                            // onKeyDown = {()=>alert('hello')}
                            borderColor={'whiteAlpha.900'}

                            borderWidth={1} borderStyle={'solid'} />

                    </Box>

                    <Box
                        width={'300px'}

                    >
                        <Text
                            marginBottom={'10px'}
                        >Confirm New Password</Text>
                        <Input

                            type='password'
                            // onKeyDown = {()=>alert('hello')}
                            borderColor={'whiteAlpha.900'}

                            borderWidth={1} borderStyle={'solid'} />

                    </Box>


                    <Button>Change</Button>




                </VStack>

                <HStack width={'50%'} marginTop={'20px'}>
                    <Spacer />

                </HStack>


            </Flex>

        </MainContainer>


    </Flex>
}
