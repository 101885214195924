import { Box, Center } from "@chakra-ui/react";
import { MouseEventHandler } from "react";

export default function MenuButton ({
    content, onClick,
    status,
  }: {content: any, onClick: MouseEventHandler, status: string}) {
    
    return <>
      <Box cursor={'pointer'} tabIndex={0}  
            // onKeyDown = {()=>alert('hello')}
             onClick = {onClick} borderColor={'whiteAlpha.900'}
            _hover = {{
              backgroundColor: '#f6b03f60'
            }}
            opacity = {status === 'active'? '1.0':'0.5'}
            width={{lg:400, base: 300, md: 500, xl: 600}} 
            padding={{lg:30, base: 7, md:30 }}
            borderWidth={1} borderStyle={'solid'}>
             {content}
            </Box>
    </>
  }