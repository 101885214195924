import { Badge, Image, Text } from "@chakra-ui/react";

export default function TitleHeader() {

    return <>

        <Image loading='lazy' src='/adventureailogo.png' width={'70px'} height="70px" />
        {/* <Text fontSize={{ lg: 50, base: '40px' }} fontWeight={{ lg: 100, base: 100 }}>MyAdventureAI </Text> */}
        <Badge marginTop={'20px'}>0.0.1 alpha</Badge>

    </>

}