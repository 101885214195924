import { Flex, VStack } from "@chakra-ui/react";
import { MainContainer } from "../components/MainContainer";
import UserCard from "../components/UserCard";


export default function UsersPage () {


    return <MainContainer>
        <VStack height={'30px'}>
        <UserCard/>
        <UserCard/>
        </VStack>
    </MainContainer>


}