import { Badge, Box, Card, Center, Flex, Image, Spacer, Text, VStack } from '@chakra-ui/react'
import axios from 'axios';
import { useNavigate } from 'react-router'
import Header from '../components/Header';
import MenuButton from '../components/MenuButton'
import TitleHeader from '../components/TitleHeader';
import { getAuthStatus, isAuthKeyValid } from '../services/auth.service';


export default function Home() {
  const navigate = useNavigate();
  return (
    <>
      <Flex

        color='whiteAlpha.900' direction="column" backgroundColor='rgb(23 24 28)'
        h={{ base: '100vh', sm: '100vh', lg: '100vh', md: '100vh', }} alignItems="center"
        w="100%"
        paddingTop={'40px'}

        overflowY='auto'
      // paddingTop={{lg:20, base:'30px'}} 
      >

        <TitleHeader/>
        <VStack spacing={{ base: 6, md: 6 }} marginTop={{ base: 30 }}>

          <MenuButton status='active' content={<Center>New Adventure</Center>} onClick={() => navigate("/game-mode")} />
          <MenuButton status='active' content={<Center>Load Adventure</Center>} onClick={() => navigate("/load-game")} />
          <MenuButton status='active' content={<Center>Account</Center>}
            onClick={async () => {
              if (await isAuthKeyValid()) {
                // this is a terrible things
                navigate("/account");
              } else {
                navigate("/auth/login")
              }

            }} />
          {/* <MenuButton status='active'  content={<Center>Pay</Center>} 
          
          onClick={
            
            async () => {
              const result = await axios.post((process.env.REACT_APP_BACKEND_URL as string) + "/accounts/checkout");
              window.location = result.data.url;
            }}
          
          /> */}

        </VStack>






      </Flex>
    </>
  )
}
