import { Badge, Box, Card, Flex, Image, Spacer, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';


export default function GamePlay() {

  const navigate = useNavigate();
  return (
    <>

      <Flex 
       
       color='whiteAlpha.900' direction="column" backgroundColor='rgb(23 24 28)' 
      h= {{base: '100vh', sm: '100vh', lg:'100vh', md: '100vh', }}  alignItems="center" 
      paddingTop={'40px'}
      w="100%"
     
       >
        <Image loading='lazy' src='/adventureailogo.png' width={'70px'} height="70px" />
        <Text fontSize={{lg:30}} fontWeight={{lg:100}}>Choose Gameplay</Text>
        
        <Box marginTop={{base:30}} paddingX={{base: '30px'}}>
          <Box cursor={'pointer'} tabIndex={0}  
          // onKeyDown = {()=>alert('hello')}
           onClick = {()=>navigate("/adventurer")} borderColor={'whiteAlpha.900'}
          width={{lg:500,}} 
          padding={{lg:30, base: '15px'}}
          borderWidth={1} borderStyle={'solid'}>
           <strong> Adventurer</strong>

            <Text marginTop={{lg:3, base: '10px'}} fontSize={{lg:15}} fontWeight={{lg:4}}>
              You present a synopsis and your character&apos;s background story 
              in the world, the AI will generate a story with its own goals, characters and events.
            </Text>
          </Box>
          <Box marginTop={{base:'30px'}} 
          //cursor={'pointer'} 
          tabIndex={0}  
          // onKeyDown = {()=>alert('hello')}
          opacity={0.4}
          borderColor={'whiteAlpha.900'}
          width={{lg:500,}} 
          padding={{lg:30, base: '15px'}}
          borderWidth={1} borderStyle={'solid'}>
           <strong> Dreamer</strong> <Badge>Coming Later</Badge>

            <Text marginTop={{lg:3}} fontSize={{lg:15}} fontWeight={{lg:4}}>
              You write a story, the AI will expand it and then put you in it. 
            </Text>
          </Box>
          <Box marginTop={{base:'30px'}} 
          //cursor={'pointer'} 
          tabIndex={0}
          // onKeyDown = {()=>alert('hello')}
          opacity={0.4}
            borderColor={'whiteAlpha.900'}
            width={{lg:500,}} 
            padding={{lg:30, base: '15px'}}
            borderWidth={1} borderStyle={'solid'}>
            Narrator <Badge>Coming Later</Badge>

            <Text marginTop={{lg:3}} fontSize={{lg:15}} fontWeight={{lg:4}}>
              Narrate a story with AI as your co-narrator. 
            </Text>
          </Box>
          </Box>

        </Flex>
    </>
  )
}
