
import { Box, Button, Card, Center, Flex, FormControl, FormLabel, Grid, GridItem, HStack, Input, Spacer, Text, useDisclosure, useToast, VStack } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'

import GenerationService from '../services/generation.service'
import { MdSave, MdShare } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { getAuthStatus, isAuthKeyValid } from '../services/auth.service';
import { saveGame } from '../services/game.service';
import LoadingModal from '../components/LoadingModal';

export default function Game() {

    //adventurer/game
    // after remove ui, play again, save preset, share publically.
    // upper ui elements for app operations {save, end, share, start over, back}
    // lower ui elements form in-game ui
    // in-game item: inventory,

    const [scene, setScene] = useState(localStorage.getItem('first_scene_result') as string);
    const [choice, setChoice] = useState(0);
    const [isLoadingOpen, setIsLoadingOpen] = useState<boolean>(false)

    const generationService = new GenerationService();

    const moveToNextScene = async () => {


        setIsLoadingOpen(true);

        if (choice === 0)
            return;


        //generate then update session prompt.

        //generate with choice then update session prompt.
        const userInput = {
            role: "user",
            content: `
            I choose option ${choice}
            `
        };
        const result = await generationService.generateWithObject(userInput);

        generationService.updateSessionPrompt(userInput, { role: "assistant", content: result })

        setScene(result); setChoice(0);

        setIsLoadingOpen(false);


    }

    useEffect(() => {
        const lastGeneration = localStorage.getItem('s_p') as string | null;
        if (lastGeneration) {
            let lastGenerationToArray = (JSON.parse(lastGeneration) as object[])
            let option = lastGenerationToArray[lastGenerationToArray.length - 1] as { content: string, role: string }
            if (option.role !== "assistant") {
                lastGenerationToArray.pop();
                option = lastGenerationToArray[lastGenerationToArray.length - 1] as { content: string, role: string }

            }

            setScene(option.content);

        }

    }, [])

    return (
        <>
            <Flex

                color='whiteAlpha.900' direction="column" backgroundColor='rgb(23 24 28)'
                alignItems="center"
                justifyContent={'center'}
                w="100%"
                h={{ base: '100vh', lg: '100vh' }}

                overflowY={'hidden'}
            >

                <LoadingModal isOpen={isLoadingOpen} message = {'Loading next scene....'} />


                <Text fontSize={{ lg: 30 }} fontWeight={{ lg: 100 }}
                    marginTop='20px'
                >
                    CyberJunk 2099
                </Text>


                <Box
                    width={{ base: '95%', lg: '90%', }}
                    height='100%'
                    marginTop={'20px'}

                >
                    <StoryTextPane
                        scene={scene} choice={choice}
                        setChoice={setChoice}


                    />



                    <HStack width={{ lg: '90%', base: '100%' }}
                        paddingBottom={'10px'}

                        backgroundColor='rgb(23 24 28)'

                        position={{ base: "fixed", lg: 'absolute' }}
                        bottom={'0px'}
                        left={{ base: '0px', lg: 'unset' }}
                        paddingX={{ base: '10px', lg: '0px' }}
                    >

                        <Input disabled tabIndex={0}

                            borderRadius={0}
                            padding={{ lg: 30 }}
                            height={{ base: '60px' }}
                            borderWidth={1} borderStyle={'solid'}>

                        </Input>



                        <Box cursor={'pointer'} tabIndex={0}
                            width={{ lg: '20%' }}
                            padding="20px"
                            height={{ base: '60px' }}
                            textAlign="center"
                            justifyItems="center"

                            onClick={() => moveToNextScene()}

                            borderWidth={1} borderStyle={'solid'}>
                            Next
                        </Box>

                    </HStack>
                </Box>


            </Flex>
        </>
    )

}

function StoryTextPane({ scene, choice, setChoice }:
    { scene: string, choice: number, setChoice: Function }) {
    const [isToSave, setIsToSave] = useState <boolean> (false);


    



    const [isEnded, setIsEnded] = useState<boolean>(false);
    useEffect(() => {
        if (scene?.includes('--THE END--')) {
            setIsEnded(true)
        }
    }, [scene]);

    return <VStack
        borderColor={'whiteAlpha.900'}
        width={{ base: '100%' }}
        height={{ base: '80%', lg: '80%' }}
        padding={{ lg: 30 }}
        borderWidth={{ base: 0, lg: 1 }} borderStyle={'solid'}
        overflowY='auto'

        marginBottom={'25%'}

    >

       {
        isEnded? <GameEndModal setIsToSave = {setIsToSave}/>:
        <></>
       }

       {
        isToSave?<SaveGameModal isToSave = {isToSave} setIsToSave={setIsToSave} />:<></>
       }
        
        
        <Text marginTop={{ lg: 3 }}
            lineHeight={2}
            fontSize={{ lg: 20 }}
            whiteSpace='pre-line'
            fontWeight={{ lg: 4 }}



        >
            {scene}
        </Text>

        <Spacer h={'fit-content'} />

        {
            !isEnded ? <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={5}
                width='100%'>

                <GridItem>
                    <OptionButton choice={choice} id={1} setChoice={setChoice} />
                </GridItem>
                <GridItem>
                    <OptionButton choice={choice} id={2} setChoice={setChoice} />
                </GridItem>
                <GridItem>
                    <OptionButton choice={choice} id={3} setChoice={setChoice} />
                </GridItem>
                <GridItem>
                    <OptionButton choice={choice} id={4} setChoice={setChoice} />
                </GridItem>

            </Grid> : <></>

        }


    </VStack>

}

function OptionButton({ choice, id, setChoice }:
    { choice: number, id: number, setChoice: Function }) {

    // get global clcked, 
    const [color, setColor] = useState('transparent');
    const [hoverColor, setHoverColor] = useState("orange")

    useEffect(() => {
        if (choice === id) {
            setColor("red")
            setHoverColor("red")
        }
        else {
            setColor("transparent")
            setHoverColor("orange")
        }
    }, [choice, id])
    return <>

        <Box cursor={'pointer'} tabIndex={0}
            backgroundColor={color}
            maxWidth={{ lg: '450px' }}
            _hover={{ backgroundColor: hoverColor }}
            onClick={() => setChoice(id)}
            padding={{ lg: 5, base: '10px' }}
            borderWidth={1} borderStyle={'solid'}>
            {id}

        </Box>

    </>
}

function GameEndModal (props: any) {


    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect (() => {

        async function isAuthKey () {
            const result = await isAuthKeyValid();
            setIsLoggedIn(result);
        }

        isAuthKey();
       


    })

    return  <Modal isOpen={true} onClose={onClose} >
    <ModalOverlay />
    <ModalContent marginX={'20px'}
        marginY='auto'
        backgroundColor='rgb(23 24 28)' color={'whiteAlpha.900'}
    >

        <ModalHeader><Center>Game Ended</Center></ModalHeader>

        <ModalBody>
            <VStack width={'100%'} height='100%'>

                <Button width={'70%'} backgroundColor='orange.700'
                   onClick={() => navigate('/adventurer')}
                >Play Again</Button>

                <Button width={'70%'} backgroundColor='#f6b03f60'
                    onClick={() => navigate('/')}
                >Go Home</Button>

                <Button width={'70%'}
                isDisabled={!isLoggedIn}
                backgroundColor='cyan.700'

                    onClick={async () => {
                        props.setIsToSave(true);
                        
                    }}

                >Save</Button>
                

                {
                    !isLoggedIn?<Text color = "red.300"><small>You must be logged in to save or share your adventure</small></Text>:<></>
                }

            </VStack>
        </ModalBody>

        <ModalFooter>

        </ModalFooter>
    </ModalContent>
</Modal>
}

function SaveGameModal(props: any) {

    const gameTitle = useRef<string>('');
    const toast = useToast();
    const navigate = useNavigate();
    const [isTitleError, setIsTitleError] = useState<boolean>(false)
    

    const handleSaveAction = async (type: string) => {

        // at this point they should already be logged in

        if (gameTitle.current.length < 3) {
           
            setIsTitleError(true);

            return;
        }

        const data = {
            title: gameTitle.current,
            preset: localStorage.getItem('preset') || "none",
            type: type,
            mode: 'singleplayer',
            gameplay: 'adventurer',
        }

        try {
            await saveGame(data);
            toast({
                title: "Save Succesful",
                status: "success",
                position: 'top',
                duration: 2000
            });
           //reroute to adventuer with the previos preset.
           // same as play again.
            navigate("/");
        } catch (e) {
            console.log(e)
            toast({
                title: "Something went wrong. Try again.",
                status: "warning",
                position: 'top',
                duration: 2000
            });

        }


    }


    return <><Modal isOpen={props.isToSave}  onClose={() => props.setIsToSave(false)} >
        <ModalOverlay />
        <ModalContent marginX={'20px'}
            marginY='auto'
            backgroundColor='rgb(23 24 28)' color={'whiteAlpha.900'}
        >

            <ModalHeader><Center>Save Game</Center></ModalHeader>

            <ModalBody>
                <VStack width={'100%'} height='100%' spacing={'40px'}>


                    <FormControl>
                        <FormLabel>Title</FormLabel>
                        <Input type={'text'}
                            placeholder='e.g Rise of The Ancients'
                            onChange={(e) => { gameTitle.current = e.target.value }}
                            
                        />

                       {
                        isTitleError? <Center marginTop={'10px'}><Text color={'red.400'}>
                        <small>Title is not long enough</small>
                         </Text></Center>:<></>
                       }


                    </FormControl>
                    <VStack width={'100%'} height='100%'>
                        <Button width={'70%'} backgroundColor='#f6b03f60'
                            onClick={() => {
                                handleSaveAction('private')
                            }}

                        >Save</Button>

                        

                        
                    </VStack>
                    {/*  */}


                </VStack>
            </ModalBody>

            <ModalFooter>

            </ModalFooter>
        </ModalContent>
    </Modal>
    </>
}
