import {
    Box, Button, Center, Flex, HStack,
    Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter,
    ModalHeader, ModalOverlay, Spacer, Switch, Tag, Text, Textarea, useToast, VStack
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-router-dom";
import HomeSidebar from "../../components/SideBar";
import { MainContainer } from "../../components/MainContainer";
import { isAuthKeyValid } from "../../services/auth.service";
import { MdCategory, MdGames, MdPeople, MdPerson } from "react-icons/md";
import UserCard from "../../components/UserCard";
import { loadGamesForAuthUser } from "../../services/game.service";

export default function AccountPage() {

    const [games, setGames] = useState<any[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        async function verifyAuth() {
            if (!(await isAuthKeyValid())) {

                navigate("/auth/login", { replace: true });

            }
        }

        async function loadUserGame() {
            const result = await loadGamesForAuthUser('all', null);
            setGames(result.data);
        }

        verifyAuth();
        loadUserGame();
        // you also want to load.
        // their card data {username, stars, no_stories, played, plays earned, last seen, data joined}
        // public stories, private stories.
    }, [])

    return <Flex direction="row">

        <HomeSidebar />
        <MainContainer>
            <Center
                flexDir={'column'}
            >
                <UserCard />

                <HStack justifyContent='center'

                    marginTop={'20px'}

                >

                    <Tag backgroundColor={'whiteAlpha.900'}>All</Tag>
                    <Tag backgroundColor={'grey'}>Public</Tag>
                    <Tag backgroundColor={'grey'}>Private</Tag>
                    <Tag backgroundColor={'grey'}>Played</Tag>
                    <Tag backgroundColor={'grey'}>Favourites</Tag>

                </HStack>

                <VStack
                    color='whiteAlpha.900'

                    spacing={{ base: '30px' }} marginTop={{ lg: 30, base: '40px' }}>

                        {
                            games.map((data, key) => (
                                <GameItemCard
                                    data = {data}
                                    key={key}
                                />
                            ))
                        }
                        

                  


                </VStack>
            </Center>

        </MainContainer>


    </Flex>
}

function GameItemCard(props: any) {

    return <Box
        borderStyle='solid'
        borderWidth={'1px'}
        borderColor={'whiteAlpha.900'}
        backgroundColor='rgb(29, 30, 35)'
        padding={'10px'}

        cursor='pointer'


        minW={{ lg: 400, base: '400px', md: 500, xl: 600 }}
        maxW={{ lg: 400, base: '400px', md: 500, xl: 600 }} >
            
        <Text
            fontSize={'30px'}
        >{props.data.title}</Text>
        {/* when we start adding images <Box height={'150px'} ></Box> */}
        <Box

            marginTop={'20px'}

        >
            {props.data.preset}
        </Box>

        <HStack marginTop={'20px'} spacing='10px'>
            <HStack spacing={'0px'}><MdPerson /><Link to={'/home'}>@faroukianoxide</Link></HStack>

            {/* <HStack spacing={'2px'}><MdOutlineGamepad/><Text>22</Text></HStack> */}

            <HStack spacing={'2px'}><MdCategory /><Text>{props.data.gameplay}</Text></HStack>

            <HStack spacing={'2px'}><MdGames /><Text>{props.data.mode}</Text></HStack>


        </HStack>
    </Box>
}
