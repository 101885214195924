import axios from 'axios'
import GenerationService from './generation.service';


export const login = async (username: string, password: string) => {

     const result = await axios.post(process.env.REACT_APP_BACKEND_URL+"/auth/login", {
        username: username,
        password: password
    });
    
    localStorage.setItem('auth_token', result.data.token);

}

export const signup = async (username: string, email: string, password: string ) => {

    const result = await axios.post(process.env.REACT_APP_BACKEND_URL+"/auth/signup", {
        username: username,
        email: email,
        password: password
    });

    localStorage.setItem('auth_token', result.data.token);

}

export const isOpenAIKeyValid = async (key: string) => {

    try  {
        await GenerationService.verifyOpenAIKey(key)
        return true;
    } catch (e) {
        return false;
    }
    
}

export const isAuthKeyValid = async () => {

    try {
        await axios.post(process.env.REACT_APP_BACKEND_URL+"/auth/verify", null, {
            headers: {
                Authorization: localStorage.getItem('auth_token') as string
            }
        })

        return true;
    } catch (e) {
        return false;
    }
   
}




export const getAuthStatus =  async (): Promise<number>  => {

    //  on go, check fields.
    //      on fields:
    //     if logged in and open ai key is present => go. 1
    //     if logged in and open ai key not present => ask for key. 2
    //     if not logged in and open ai key present => ask if to login. 3
    //     if not logged in and open ai key not present => do now (ask for login and oai key) 4

    
    
    const authKey = await isAuthKeyValid();
    const openAIKey = await isOpenAIKeyValid(sessionStorage.getItem('key') as string);

    if (authKey && openAIKey) return 1;
    
    if (authKey && !openAIKey) return 2;
    
    if (!authKey && openAIKey) return 3;

    return 4;

}


