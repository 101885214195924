import { Box, Grid, GridItem, HStack, Text, Tooltip } from "@chakra-ui/react";
import { MdPerson } from "react-icons/md";

export default function UserCard() {

    return <HStack

        color='whiteAlpha.900' direction="column"
        alignItems="top"

        minW={{ lg: 400, base: '400px', md: 500, xl: 600 }}
        backgroundColor='#17181c'
        spacing={'50px'}
        paddingY='30px'
        paddingX={'30px'}
    >

        <Box>

            <MdPerson size={'100px'} />

        </Box>

        <Box alignItems={'left'}>
            <Text fontSize={'30px'}>@faroukianoxide</Text>
            <Text>Stories from the beyond.</Text>
            <Grid
                marginTop={'30px'}
                templateColumns={{ base: 'repeat(2, 1fr)' }} gap={5}
                width='100%'>


                <GridItem>

                    <Tooltip label='Stars earned' fontSize='md'>
                        44⭐
                    </Tooltip>
                </GridItem>
                <GridItem>
                    <Tooltip label='Adventures created' fontSize='md'>
                        5📜
                    </Tooltip>

                </GridItem>
                <GridItem>
                    <Tooltip label='Adventures played' fontSize='md'>
                        12🎮
                    </Tooltip>

                </GridItem>
                <GridItem>
                    <Tooltip label='No of times people played their adventures' fontSize='md'>
                        144🎮
                    </Tooltip>
                </GridItem>

                <GridItem>
                    Joined: 22/4/2022
                </GridItem>
                <GridItem>  

                </GridItem>


            </Grid>

        </Box>


    </HStack>
}