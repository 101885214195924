import { Badge, Box, Button, Card, Center, Flex, HStack, Spacer, Switch, Tag, Text, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { MdCategory, MdGamepad, MdGames, MdOutlineGamepad, MdPerson, MdScoreboard } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import MenuButton from '../components/MenuButton';
import TitleHeader from '../components/TitleHeader';
import { loadPublicGames } from '../services/game.service';


export default function LoadGame() {

  const [games, setGames] = useState<any[]>([]);

  useEffect(() => {

    async function loadUserGame() {
      // const result = await loadPublicGames('all', null);
      // setGames(result.data);
  }
  }, [])


  function handleGameItemClick() {

    const preset = {
      world: `
      In 2077, technology has taken over everything and humans have discovered various ways to fuse themselves with machines. Crime has increased and political figures are constantly seeking ways to control the lives of everybody.
      `,
      you: `
      I am H, a detective working for the Bureau of Equilibrium. I have always hated being an "augmented", which is what they call any human with machine parts in them. However, I had no choice when I suffered fatal damages in an explosion 3 years ago.
      `,

      purpose: `

      My mission is to investigate "The Spider" a powerful augmentation device which is rumoured to be in development by Helm Labs, a research facility owned by Rickard Helm, the richest man in the world. If this device is successfully built and harnessed, it can give any human the ability to control the whole of humanity. I can't let that happen. I must find and destroy anything related to this development..or anyone.
      
      `
    }

    localStorage.setItem('preset', JSON.stringify(preset));

    navigate('/adventurer');



  }

  const navigate = useNavigate();
  return (
    <>

      <Flex

        color='whiteAlpha.900' direction="column" backgroundColor='rgb(23 24 28)'
        h={{ base: '100vh', sm: '100vh', lg: '100vh', md: '100vh', }}
        alignItems="center"

        w="100%"
        overflowY={'auto'}
        
       
        paddingY='10px'
        paddingBottom='30px'
      >

        <HStack
        width={'50%'}
        

        marginBottom='10px'
        >
          
          
          
          
          <Spacer/>
          <MdPerson size={'40px'}/>

        </HStack>
        <TitleHeader />
        <Text fontSize={{ lg: '15px', base: '12px' }}
          marginTop='20px'
          fontWeight={{ lg: 100, base: 100 }}>Play an adventure from our creators to get lost in or create own.</Text>

        {/* <HStack justifyContent='center'><Text> Private</Text> <Switch id='email-alerts' /><Text>Public</Text></HStack>
         */}
        <HStack justifyContent='center'

          marginTop={'20px'}

        >

          <Tag backgroundColor={'whiteAlpha.900'}>All</Tag>
          <Tag backgroundColor={'grey'}>Public</Tag>
          <Tag backgroundColor={'grey'}>Private</Tag>
          <Tag backgroundColor={'grey'}>Played</Tag>
          <Tag backgroundColor={'grey'}>Favourites</Tag>



        </HStack>

        <HStack justifyContent='center'

          marginTop={'10px'}

        >

          <Tag backgroundColor={'whiteAlpha.900'}>All</Tag>
          <Tag backgroundColor={'grey'}>Multiplayer</Tag>
          <Tag backgroundColor={'grey'}>Singleplayer</Tag>
          <Tag backgroundColor={'grey'}>Adventurer</Tag>
        



        </HStack>

        <HStack w={{ lg: 400, base: '400px', md: 500, xl: 600 }}>
          <Spacer/>
          
          <Button>Create</Button>
        </HStack>

        <VStack spacing={{ base: '30px' }} marginTop={{ lg: '20px', base: '40px' }}>

          <Box
            borderStyle='solid'
            borderWidth={'1px'}
            borderColor={'whiteAlpha.900'}
            backgroundColor='rgb(29, 30, 35)'
            padding={'10px'}

            cursor='pointer'

            onClick={handleGameItemClick}

            maxW={{ lg: 400, base: '400px', md: 500, xl: 600 }} >
            <Text
              fontSize={'30px'}
            >Cyberjunk 2099</Text>
            {/* when we start adding images <Box height={'150px'} ></Box> */}
            <Box

              marginTop={'20px'}

            >
              For accessibility, it is recommended to block scrolling on the main
              document behind the modal. Chakra does this by default but you can set
              blockScrollOnMount to false to allow scrolling behind the modal.
            </Box>

            <HStack marginTop={'20px'} spacing='10px'>
              <HStack spacing={'0px'}><MdPerson /><Link to={'/home'}>@faroukianoxide</Link></HStack>

              {/* <HStack spacing={'2px'}><MdOutlineGamepad/><Text>22</Text></HStack> */}

              <HStack spacing={'2px'}><MdCategory /><Text>Adventurer</Text></HStack>


            </HStack>
          </Box>

          <Box
            borderStyle='solid'
            borderWidth={'1px'}
            borderColor={'whiteAlpha.900'}
            backgroundColor='rgb(29, 30, 35)'
            padding={'10px'}

            cursor='pointer'

            onClick={handleGameItemClick}

            maxW={{ lg: 400, base: '400px', md: 500, xl: 600 }} >
            <Text
              fontSize={'30px'}
            >Cyberjunk 2099</Text>
            {/* when we start adding images <Box height={'150px'} ></Box> */}
            <Box

              marginTop={'20px'}

            >
              For accessibility, it is recommended to block scrolling on the main
              document behind the modal. Chakra does this by default but you can set
              blockScrollOnMount to false to allow scrolling behind the modal.
            </Box>

            <HStack marginTop={'20px'} spacing='10px'>
              <HStack spacing={'0px'}><MdPerson /><Link to={'/home'}>@faroukianoxide</Link></HStack>

              {/* <HStack spacing={'2px'}><MdOutlineGamepad/><Text>22</Text></HStack> */}

              <HStack spacing={'2px'}><MdCategory /><Text>Adventurer</Text></HStack>


            </HStack>
          </Box>


        </VStack>

        {/* <HStack width={'50%'} marginTop={'20px'}>
          <Spacer />
          <Button width={'100%'} backgroundColor='transparent'
            borderColor={'white'}
            borderWidth='1px'

          >Load more</Button>
        </HStack> */}


      </Flex>
    </>
  )
}

function LoadedGameComponent() {


  return <>
    <Box
      borderStyle='solid'
      borderWidth={'1px'}
      borderColor={'whiteAlpha.900'}
      backgroundColor='rgb(29, 30, 35)'
      padding={'10px'}

      maxW={{ lg: 400, base: 400, md: 500, xl: 600 }} >
      <Text
        fontSize={'30px'}
      >Cyberjunk 2099</Text>
      {/* when we start adding images <Box height={'150px'} ></Box> */}
      <Box

        marginTop={'20px'}

      >
        For accessibility, it is recommended to block scrolling on the main
        document behind the modal. Chakra does this by default but you can set
        blockScrollOnMount to false to allow scrolling behind the modal.
      </Box>

      <HStack marginTop={'20px'} spacing='10px'>
        <HStack spacing={'2px'}><MdPerson /><Link to={'/home'}>@faroukianoxide</Link></HStack>

        {/* <HStack spacing={'2px'}><MdOutlineGamepad/><Text>22</Text></HStack> */}

        <HStack spacing={'2px'}><MdCategory /><Text>Adventurer</Text></HStack>


      </HStack>
    </Box>

  </>
}
