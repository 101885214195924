import { Box, Button, Card, Center, Flex, HStack, Input, Spacer, Text, Textarea, useDisclosure, useToast, VStack } from '@chakra-ui/react'
import { type } from '@testing-library/user-event/dist/type';
import { useEffect, useRef, useState } from 'react'
import { Router, useNavigate } from 'react-router-dom';
import { getAuthStatus } from '../services/auth.service';
import GenerationService from '../services/generation.service';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import { MdPlayArrow } from 'react-icons/md';
import LoadingModal from '../components/LoadingModal';


export default function Adventurer() {

    const [world, setWorld] = useState('');
    const [you, setYou] = useState('');
    const [purpose, setPurpose] = useState('');
    const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false)

    const navigate = useNavigate();
    const toast = useToast();

    const [isLoadingOpen, setIsLoadingOpen] = useState<boolean>(false);



    useEffect(() => {
        //overwrite what was already there.
        //notefib: users should not just jump to a page without following a progression:
        // what if they just went to /game without the necessary progression // => return them home.
        const presetString = localStorage.getItem('preset');
        if (presetString) {
            const preset: { world: string, you: string, purpose: string } = JSON.parse(presetString as string);
            setWorld(preset.world.trim());
            setPurpose(preset.purpose.trim());
            setYou(preset.you.trim());
        }

        localStorage.removeItem('preset');
        localStorage.removeItem('s_p');


    }, [])


    const generationService = new GenerationService();

    const handleFormSubmission = async () => {

        setIsLoadingOpen(true);

        const authStatus = await getAuthStatus();

        if (world.length < 50 && you.length < 50 && purpose.length < 50) {
            toast({
                title: "Please fill the fields",
                status: "warning",
                position: 'top'
            })
            return;

        }


        const userInput = {
            role: "user",
            content: `World: ${world}   
                Me: ${you}

                My Purpose: ${purpose}
       
            `}




        if (authStatus === 1 || authStatus === 3) {
            const result = await generationService.generateWithObject(userInput)
            localStorage.setItem('preset', JSON.stringify({ world: world, purpose: purpose, you: you }));
            generationService.updateSessionPrompt(userInput, { role: "assistant", content: result });
            navigate('/game');

        } else {
            setIsLoadingOpen(false);
            setIsAuthModalOpen(true);
            return;
        }

    }

    return (
        <>
            <Flex

                color='whiteAlpha.900' direction="column" backgroundColor='rgb(23 24 28)'
                h={{ base: '100%', lg: '100%', md: '100%', xl: '100vh' }}
                alignItems="center"
                justifyContent={'center'}
                w="100%"
                paddingY={{ lg: '30px', base: '30px' }}
                paddingBottom={{base: '30px'}}
                paddingX={{ lg: '15px'}}
                overflowY={'auto'}
            >
                <Flex h={'100%'} >
                    <form onSubmit={(e) => { e.preventDefault(); handleFormSubmission(); }}>


                        <Center>
                            <VStack>
                                <Text fontSize={{ lg: 30, base: '30px' }} fontWeight={{ lg: 100 }}>Adventurer</Text>
                                <small>Create a new adventure or <Link
                                    style={{ textDecoration: 'underline' }} to={'/load-game'}>load an adventure</Link></small>
                            </VStack>
                        </Center>
                        <VStack spacing={'40px'} marginTop={{ base: 30 }} alignItems="left"
                            width = {{base:'90vw', lg: '60vw'}}>

                                
                            <Box
                            >


                                <VStack alignItems={'left'} spacing='1px'
                                    marginBottom={'10px'}>
                                    <Text

                                    >The World </Text>
                                    <small>Describe the world</small>
                                </VStack>
                                
                                <Textarea

                                    value={world}
                                    required
                                    // onKeyDown = {()=>alert('hello')}
                                    borderColor={'whiteAlpha.900'}
                                    height={'150px'}
                                   
                                    onChange={(e) => setWorld(e.target.value)}

                                    padding={{ lg: 30 }}
                                    borderWidth={1} borderStyle={'solid'} />


                            </Box>

                            <Box

                            >

                                <LoadingModal isOpen={isLoadingOpen} />

                                <AuthModal
                                    isOpen={isAuthModalOpen}
                                    //view = {}
                                    setIsAuthModalOpen={setIsAuthModalOpen} />


                                <VStack alignItems={'left'} spacing='1px'
                                    marginBottom={'10px'}>
                                    <Text

                                    >You </Text>
                                    <small>Describe who you are in this world</small>
                                </VStack>

                                <Textarea
                                    required
                                    value={you}
                                    // onKeyDown = {()=>alert('hello')}
                                    height={'150px'}
                                    borderColor={'whiteAlpha.900'}
                                    onChange={(e) => setYou(e.target.value)}
                                    padding={{ lg: 30 }}
                                    borderWidth={1} borderStyle={'solid'} />

                            </Box>

                            <Box>

                            <VStack alignItems={'left'} spacing='1px'
                                    marginBottom={'10px'}>
                                    <Text

                                    >Your Purpose </Text>
                                    <small>Descibe what your purpose in this world is</small>
                                </VStack>
                                <Textarea cursor={'pointer'} tabIndex={0}
                                    // onKeyDown = {()=>alert('hello')}
                                    value={purpose}
                                    required
                                    height={'150px'}
                                    borderColor={'whiteAlpha.900'}
                                    onChange={(e) => setPurpose(e.target.value)}
                                    padding={{ lg: 30 }}
                                    borderWidth={1} borderStyle={'solid'} />

                            </Box>





                        </VStack>

                        <Button

                            type='submit'
                            textAlign="center"
                            justifyItems="center"
                            borderRadius={0}
                            backgroundColor="transparent"
                            marginTop={'40px'}
                            width={{ base: '100%' }}
                            minHeight={50}
                            maxHeight={50}
                            borderWidth={1} borderStyle={'solid'}
                        >
                            Go
                        </Button>

                    </form>

                </Flex>

            </Flex>
        </>
    )
}

function AuthModal(props: any) {

    const openAIKey = useRef('');
    const toast = useToast();
    const [isButtonLoaded, setIsButtonLoading] = useState(false)

    useEffect(() => {

    })


    const confirmUserKey = async () => {

        (document.getElementById("idd") as any).ariaDisabled = true;

        try {
            setIsButtonLoading(true)
            await GenerationService.verifyOpenAIKey(openAIKey.current)
            sessionStorage.setItem('key', openAIKey.current);
            toast({
                title: "Key has been validated and only active for this tab.",
                position: 'top',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });

            (document.getElementById("idd") as any).ariaDisabled = false;



        } catch (e) {

            (document.getElementById("idd") as any).ariaDisabled = false;
            console.log(e)
            toast({
                title: "The key you provided seems to be invalid. Please try another key.",
                position: 'top',
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        }
    }

    const authDecisionPane = <>
        <ModalHeader><Center>How do you want to play?</Center></ModalHeader>
        <ModalCloseButton />

        <ModalBody padding={'30px'}>

            <VStack width={'100%'}>

                <Button width={'70%'} backgroundColor='orange.700'>Login to server</Button>
                <Button width={'70%'} backgroundColor='#f6b03f60'
                    onClick={() => { setModalContent(openAIKeyPane) }}
                >Use device instead</Button>

            </VStack>
            <Center marginTop={'30px'}><Text fontSize={'13px'}>Note: You need your OpenAI key to play</Text></Center>


        </ModalBody>
    </>

    const openAIKeyPane = <>
        <ModalHeader><HStack width={'70%'}>
            <Text onClick={() => setModalContent(authDecisionPane)}>{"<-"}</Text>
            <Spacer />
            <Text>Enter your OpenAI key</Text>
        </HStack></ModalHeader>
        <ModalCloseButton />

        <ModalBody padding={'30px'}>

            <VStack width={'100%'}>
                <Input
                    type={'password'}

                    onChange={(e) => {
                        openAIKey.current = e.target.value
                    }}

                />

                <Button width={'70%'} backgroundColor='#f6b03f60'
                    isLoading={isButtonLoaded}
                    id='idd'

                    onClick={confirmUserKey}

                >Use Key</Button>

            </VStack>
            <Center marginTop={'30px'}><Text textAlign={'center'} fontSize={'13px'}>
                Note: Your key will never be saved anywhere else and
                is deleted once you close this tab. <Link to={'/dfdf'}>
                    <Text decoration='underline'

                    >Learn more.</Text></Link>
            </Text></Center>


        </ModalBody>
    </>

    const [modalContent, setModalContent] = useState(authDecisionPane);
    return (
        <>

            <Modal isOpen={props.isOpen} onClose={() => props.setIsAuthModalOpen(false)}>
                <ModalOverlay />
                <ModalContent marginY={'auto'} backgroundColor='rgb(23 24 28)' color={'whiteAlpha.900'}>
                    <AuthModalContent>
                        {modalContent}
                    </AuthModalContent>
                </ModalContent>
            </Modal>
        </>
    )
}

function AuthModalContent(props: any) {


    return <Box>
        {props.children}
    </Box>
}


