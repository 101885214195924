import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import Home from './pages/Home'
import Mode from './pages/Gameplay';
import Game from './pages/Game';
import Adventurer from './pages/Adventurer';
import NewGamePage from './pages/GameMode';
import AuthPage from './pages/Auth';
import LoginPage from './pages/Login';
import GameMode from './pages/GameMode';
import GamePlay from './pages/Gameplay';
import LoadGame from './pages/LoadGame';
import AccountPage from './pages/Account/Account';
import SignupPage from './pages/Signup';
import UserProfilePage from './pages/UserProfile';
import UsersPage from './pages/UsersPage';
import AccountSettingsPage from './pages/Account/Settings';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>
  },
  {
    path: "/auth",
    element: <AuthPage/>
  },
  {
    path: "/auth/login",
    element: <LoginPage/>
  },
  {
    path: "/auth/signup",
    element: <SignupPage/>
  },
  {
    path: "/game-mode",
    element: <GameMode/>
  },
  {
    path: "/load-game",
    element: <LoadGame/>
  },
  {
    path: "/gameplay",
    element: <GamePlay/>
  },
  {
    path: "/account",
    element: <AccountPage/>
  },
  {
    path: "/account/settings",
    element: <AccountSettingsPage/>
  },
  {
    path: "/adventurer",
    element: <Adventurer/>
  },
  {
    path: "/game",
    element: <Game/>
  },

  {
    path: "/users",
    element: <UsersPage/>
  },

  {
    path: "/users/faroukianoxide",
    element: <UserProfilePage/>
  },

  {
    path: "/users/faroukianoxide",
    element: <UserProfilePage/>
  },
  
]);
root.render(
  
    <ChakraProvider>
      <RouterProvider router={router}></RouterProvider>
    </ChakraProvider>
    
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
