import { Flex, Box, Text, AccordionPanel, AccordionIcon, AccordionButton, AccordionItem, Accordion, Divider, Button } from "@chakra-ui/react";
import { MdCreditCard, MdPerson, MdBrowseGallery, MdCollections, MdSettings, MdHome
     } from "react-icons/md";
import { SideBarItem } from "./SideBarItem";
import { useNavigate, useParams } from "react-router-dom";

export default function HomeSidebar() {

    const { id } = useParams();
    const navigate = useNavigate();


    const prefix = `/agents/${id}`;
    return <Flex
        color='whiteAlpha.900'
        height={{ lg: '100vh' }}
        backgroundColor= '#17181c'
        width={{ lg: '20%' }}
        overflowY={'auto'}
        boxShadow='lg'
    >

        <Box marginTop={'40px'} paddingLeft={'30px'}>
            <SideBarItem text="Home" link="/" icon={<MdHome />} />
            <SideBarItem text="Profile" link="/account" icon={<MdPerson />} />
            {/* <SideBarItem text="Subscription" link="subscription" icon={<MdCreditCard />} /> */}
            <SideBarItem text="Settings" link="/account/settings" icon={<MdSettings />} />
            
        

        </Box>



    </Flex>

}