import { Box, Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

export function MainContainer(props: any) {

    return <Flex 
    flexDir={'column'}
    width = "100%"
    height={'100vh'}
    overflowY='auto'
    backgroundColor='#070707'
    paddingBottom={'50px'}
    >
        
           <Box paddingTop={'50px'}>

            {props.children}
            </Box>


       

    </Flex>
}