import { Badge, Box, Card, Center, Flex, Image, Spacer, Text, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import MenuButton from '../components/MenuButton';


export default function GameMode() {

  const navigate = useNavigate();
  return (
    <>

      <Flex 
       
       color='whiteAlpha.900' direction="column" backgroundColor='rgb(23 24 28)' 
      h= {{base: '100vh', sm: '100vh', lg:'100vh', md: '100vh', }}  alignItems="center" 
      
      w="100%"
      paddingBottom={300}
      paddingTop={'40px'}
      >
        <Image loading='lazy' src='/adventureailogo.png' width={'70px'} height="70px" />
        <Text marginTop={'20px'} fontSize={{lg:30}} fontWeight={{lg:100}}>Choose a Game Mode</Text>
        
        <VStack spacing={{ base: 6, md: 6}} marginTop={{base: '30px', lg:30}}>
        <MenuButton status='active' content="Single Player" onClick={() =>navigate("/gameplay")}/>
        <MenuButton status='inactive' content={<>Multiplayer <Badge>Coming Later</Badge></>} onClick={() =>{}}/>       
          </VStack>

        </Flex>
    </>
  )
}
