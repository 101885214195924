import { Box, Card, Center, Flex, Spacer, Text } from '@chakra-ui/react'


export default function AuthPage() {
  return (
    <>
      <Flex

        color='whiteAlpha.900' direction="column" backgroundColor='rgb(23 24 28)'
        h={{ base: '100vh', sm: '100vh', lg: '100vh', md: '100vh', }} alignItems="center"
        w="100%"
        paddingBottom={300}
        paddingTop={{ lg: 20 }} >
        <Text fontSize={{ lg: 30 }} fontWeight={{ lg: 100 }}>MyAdventureAI</Text>
        <Box marginTop={{ lg: 30 }}>
          <Box cursor={'pointer'} tabIndex={0}
            // onKeyDown = {()=>alert('hello')}
            onClick={() => alert('hello')} borderColor={'whiteAlpha.900'}
            _hover={{
              backgroundColor: 'red'
            }}
            width={{ lg: 500 }}
            padding={{ lg: 30 }}
            borderWidth={1} borderStyle={'solid'}>
            <Center>Login</Center>
          </Box>
          <Box marginTop={{ lg: 3 }} cursor={'pointer'} tabIndex={0}
            // onKeyDown = {()=>alert('hello')}
            onClick={() => alert('hello')} borderColor={'whiteAlpha.900'}
            width={{ lg: 500 }}
            padding={{ lg: 30 }}
            borderWidth={1} borderStyle={'solid'}>
            <Center>Signup</Center>
          </Box>
          <Center>or</Center>

          <Box cursor={'pointer'} tabIndex={0}
            // onKeyDown = {()=>alert('hello')}
            onClick={() => alert('hello')} borderColor={'whiteAlpha.900'}
            _hover={{
              backgroundColor: 'red'
            }}
            width={{ lg: 500 }}
            padding={{ lg: 30 }}
            borderWidth={1} borderStyle={'solid'}>
            <Center>Login</Center>
          </Box>
          <Box marginTop={{ lg: 3 }} cursor={'pointer'} tabIndex={0}
            // onKeyDown = {()=>alert('hello')}
            onClick={() => alert('hello')} borderColor={'whiteAlpha.900'}
            width={{ lg: 500 }}
            padding={{ lg: 30 }}
            borderWidth={1} borderStyle={'solid'}>
            <Center>Signup</Center>
          </Box>
         

        </Box>






      </Flex>
    </>
  )
}
