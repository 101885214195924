import axios from "axios";



export default class GenerationService {

    private sessionPrompt = [
        {
            role: "system", content: `
    
            You are a text based adventure game master who:
            1. Generates fun plotlines
            2. Takes a user's actions
            3. Generates four (4) options that can be taken by the users after which you then continue the loop from step 1 again.
            Your narrations must be in the second person.

            You must end the story when the main story is over, do not bore the player with unnecessary extra storyline.

            When you have completed the story, add --THE END-- to the end of you final generated output as it helps important perform the next action.

            `,
        },
    ]

    private persistSessionPrompt = () => {
        localStorage.setItem('s_p', JSON.stringify(this.sessionPrompt));
    }

    private loadSessionPrompt = () => {
        const sessionPrompt = localStorage.getItem('s_p');
        return sessionPrompt?JSON.parse(sessionPrompt):this.sessionPrompt;
    }


    protected generate = async () => {
        const result = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
                model: 'gpt-3.5-turbo-16k',
                messages: this.sessionPrompt,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer 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"
                },
            }
        )
        return result.data.choices[0].message.content;
    }

    public generateWithObject = async (object: {role: string, content: string}) => {
        const result = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
                model: 'gpt-3.5-turbo-16k',
                messages: [...this.loadSessionPrompt(), object]
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer "+sessionStorage.getItem('key')
                },
            }
        )

        return result.data.choices[0].message.content;
    }

    public updateSessionPrompt = (
        userInput: {role: string, content: string},
        machineOutput: {role: string, content: string}
        ): void => {

        this.sessionPrompt = this.loadSessionPrompt(); 
        this.sessionPrompt.push(userInput, machineOutput);
        this.persistSessionPrompt();
       
    }

    public static verifyOpenAIKey = async (key: string) => {

        
       
            return axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
                model: 'gpt-3.5-turbo-16k',
                messages: [{role: "user", content: "Hello"}]
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer "+ key
                },
            }
        )
    }


    // ---- middleware service ---- //

    //listen for action.
    //listen for number of scenes.
    //listen for numbers of scene.


}



