import {
    Box, Button, Center, Flex, Grid, GridItem, HStack,
    Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter,
    ModalHeader, ModalOverlay, Spacer, Text, Textarea, Tooltip, useToast, VStack
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-router-dom";
import HomeSidebar from "../components/SideBar";
import { MainContainer } from "../components/MainContainer";
import { isAuthKeyValid } from "../services/auth.service";
import { MdCategory, MdPerson } from "react-icons/md";
import UserCard from "../components/UserCard";

export default function UserProfilePage() {

    const navigate = useNavigate();

    return <Flex direction="row">
       


    </Flex>
}