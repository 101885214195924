import { Box, Button, Card, Center, Flex, Image, Input, Spacer, Text, useToast, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../services/auth.service';


export default function LoginPage() {

  const toast = useToast();
  const navigate = useNavigate();
  const [username, setUsername] = useState('' as string);
  const [password, setPassword] = useState('' as string);
  const [isLoginLoading, setLoginLoading] = useState(false);

  
  

  const handleLogin = async () => {
    setLoginLoading(true);
    try {
      await login(username, password)
      setLoginLoading(false);
      navigate("/", {replace:true});
    } catch {
      setLoginLoading(false);
      toast({
        title: "Invalid credentials. Try again.",
        position: 'top',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
   
  }
  return (
    <>
      <Flex

        color='whiteAlpha.900' direction="column" backgroundColor='rgb(23 24 28)'
        h={{ base: '100vh', sm: '100vh', lg: '100vh', md: '100vh', }} alignItems="center"
        justifyContent={'center'}
        w="100%"
       
       >
        <Image loading='lazy' src='/adventureailogo.png' width={'70px'} height="70px" />
        <Text fontSize={{ lg: 30 }} fontWeight={{ lg: 100 }}>MyAdventureAI</Text>
        <form onSubmit={(e) => { e.preventDefault(); handleLogin() }}>
          <VStack spacing={'30px'} marginTop='30px'>



            <Input
              placeholder='username'
              padding={{ lg: 30, base: '30px' }}
              width={{ lg: 500 }}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Input
              placeholder='password'
              type={'password'}
              padding={{ lg: 30, base: '30px' }}
              width={{ lg: 500 }}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type='submit'
              backgroundColor='transparent'
              borderColor={'whiteAlpha.900'}
              width={{ lg: 500, base: '100%' }}
              padding={{ lg: 30, base: '30px' }}
              isLoading={isLoginLoading}
              colorScheme='blue'
              borderWidth={1} borderStyle={'solid'}>
              <Center>Login</Center>
            </Button>
            <Link 
            style={{textDecoration: 'underline'}}
            to={'/auth/signup'}>Create an account</Link>



          </VStack>
        </form>
      </Flex>
    </>
  )
}
