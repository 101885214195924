import axios from "axios"


export const saveGame = async (body: any) => {
    const authKey = localStorage.getItem('auth_token') as string
    return axios.post(process.env.REACT_APP_BACKEND_URL+"/games", body, {
        headers: {
            Authorization: authKey
        }
    })
}

export const loadGamesForAuthUser = async (type: string | null, mode: string | null) => {
    const authKey = localStorage.getItem('auth_token') as string
    return axios.get(process.env.REACT_APP_BACKEND_URL+`/games?type=${type}&mode=${mode}`,{
        headers: {
            Authorization: authKey
        }
    })
}

export const loadPublicGames = async (type: string | null, mode: string | null, user: string | null) => {

    return axios.get(process.env.REACT_APP_BACKEND_URL+`/games/public?type=${type}&mode=${mode}`)
}

